module.exports = {
    "GLOBAL": {
        "DUBB_PHONE_NUMBER": "(701 369-3822)",
        "DUBB_ICON_URL": "https:\/\/do.dubbcdn.com\/img\/dubb-icon.png",
        "DUBB_BLUE": "#0071bc",
        "DUBB_COMPANY_ADDRESS": "25350 Magic Mountain Pkwy. Suite 300, Valencia, CA 91355",
        "PARTNER_KEY_COOKIE_FIRST_PROMOTER_TID": "_fprom_tid",
        "PARTNER_KEY_COOKIE_FIRST_PROMOTER_REF": "_fprom_ref",
        "AFFILIATE_VENDOR_PARTNER_STACK": "partner_stack",
        "AFFILIATE_VENDOR_FIRST_PROMOTER": "first_promoter",
        "FIRST_PROMOTER_KEY_TYPE_TID": "tid",
        "FIRST_PROMOTER_KEY_TYPE_REF": "ref",
        "DEFAULT_VIDEO_TITLE_COLOR": "#333333",
        "DEFAULT_VIDEO_HEADRE_COLOR": "#ffffff",
        "DEFAULT_VIDEO_FRAME_COLOR": "#ffffff",
        "DEFAULT_CTA_TEXT_COLOR": "#ffffff",
        "DEFAULT_FORM_TITLE_COLOR": "#464E5F",
        "DEFAULT_FORM_TEXT_COLOR": "#464E5F",
        "DEFAULT_DISK": "cf-wnam",
        "DEFAULT_TIMEZONE": "America\/Los_Angeles",
        "PASSWORD_PLACEHOLDER": "**dubb-password-placeholder**",
        "VIDEO_URL_PLACEHOLDER": "{{video-url}}",
        "VIDEO_USER_NAME_PLACEHOLDER": "{{video-user-name}}",
        "VIDEO_LAYOUT_CENTERED": "centered",
        "VIDEO_LAYOUT_LEFT_JUSTIFIED": "left_justified",
        "STRIPE_TEAM_PLAN_LAUNCH": "company-monthly",
        "STRIPE_TEAM_PLAN_LAUNCH_YEARLY": "company-yearly",
        "STRIPE_TEAM_PLAN_SMALL_BUSINESS_DISCOUNTED": "small-business-discounted-monthly-20180925",
        "STRIPE_TEAM_PLAN_SMALL_BUSINESS_DISCOUNTED_YEARLY": "small-business-discounted-yearly-20180925",
        "STRIPE_TEAM_PLAN_SMALL_BUSINESS_PLUS": "small-business-plus-monthly-20180925",
        "STRIPE_TEAM_PLAN_SALES_PRO_2020_MONTHLY": "sales-pro-monthly-20200315",
        "STRIPE_TEAM_PLAN_SALES_PRO_2020_YEARLY": "sales-pro-yearly-20200315",
        "STRIPE_TEAM_PLAN_SALES_PRO_PLUS_2020_MONTHLY": "sales-pro-plus-monthly-20200315",
        "STRIPE_TEAM_PLAN_SALES_PRO_PLUS_2020_YEARLY": "sales-pro-plus-yearly-20200315",
        "STRIPE_TEAM_PLAN_SUPPORT_PRO_2020_MONTHLY": "supports-pro-monthly-20200315",
        "STRIPE_TEAM_PLAN_SUPPORT_PRO_2020_YEARLY": "supports-pro-yearly-20200315",
        "STRIPE_TEAM_PLAN_SUPPORT_PRO_PLUS_2020_MONTHLY": "supports-pro-plus-monthly-20200315",
        "STRIPE_TEAM_PLAN_SUPPORT_PRO_PLUS_2020_YEARLY": "supports-pro-plus-yearly-20200315",
        "STRIPE_TEAM_PLAN_SALES_PRO_2023_MONTHLY": "sales-pro-monthly-20230101",
        "STRIPE_TEAM_PLAN_SALES_PRO_2023_YEARLY": "sales-pro-yearly-20230101",
        "STRIPE_TEAM_PLAN_SALES_PRO_PLUS_2023_MONTHLY": "sales-pro-plus-monthly-20230101",
        "STRIPE_TEAM_PLAN_SALES_PRO_PLUS_2023_YEARLY": "sales-pro-plus-yearly-20230101",
        "DUBB_TEAM_PLAN_SMALL_BUSINESS_LITE": "small-business-lite",
        "SUBSCRIPTION_AUTOMATION": "automation",
        "SUBSCRIPTION_CONTACT": "contact",
        "SUBSCRIPTION_CUSTOM_DOMAIN": "custom_domain",
        "SUBSCRIPTION_DEFAULT": "default",
        "SUBSCRIPTION_STORAGE": "storage",
        "SUBSCRIPTION_TRANSCRIBE": "transcribe",
        "ADDON_SUBSCRIPTIONS": [
            "automation",
            "contact",
            "custom_domain",
            "storage",
            "transcribe"
        ],
        "STRIPE_AUTOMATION_PLAN_MONTHLY": "automation-addon-monthly-20200315",
        "STRIPE_AUTOMATION_PLAN_YEARLY": "automation-addon-yearly-20200315",
        "STRIPE_CONTACT_PLAN_MONTHLY": "contact-addon-monthly-20210205",
        "STRIPE_CONTACT_PLAN_YEARLY": "contact-addon-yearly-20210205",
        "STRIPE_CUSTOM_DOMAIN_PLAN_MONTHLY": "custom-domain-monthly-20210205",
        "STRIPE_CUSTOM_DOMAIN_PLAN_YEARLY": "custom-domain-yearly-20210205",
        "STRIPE_CUSTOM_DOMAIN_PLAN_2019_MONTHLY": "custom-domain-monthly-20190221",
        "STRIPE_CUSTOM_DOMAIN_PLAN_2019_YEARLY": "custom-domain-yearly-20190221",
        "STRIPE_STORAGE_PLAN_MONTHLY": "storage-monthly-20181212",
        "STRIPE_STORAGE_PLAN_YEARLY": "storage-yearly-20181212",
        "STRIPE_DUBB_SENDER_PLAN": "dubb-sender-addon-20201121",
        "STRIPE_TRANSCRIBE_PLAN": "transcribe-addon-20200407",
        "GB": 1073741824,
        "FREE_USER_STORAGE_QUOTA": 10,
        "FREE_USER_VIDEO_SIZE_QUOTA": 0.15,
        "FREE_USER_VIDEOS_QUOTA": 100,
        "UNLIMITED_QUOTA_VALUE": -1,
        "FEATURE_4K_VIDEO": "4k_video",
        "FEATURE_ACTIVITY_REPORTING_DETAIL": "activity_reporting_detail",
        "FEATURE_ACTIVITY_REPORTING_GENERAL": "activity_reporting_general",
        "FEATURE_AGGREGATED_REPORTING_GENERAL": "aggregated_reporting_general",
        "FEATURE_AGGREGATED_REPORTING_VIDEO_LEVEL": "aggregated_reporting_video_level",
        "FEATURE_AI_AGENT": "ai_agent",
        "FEATURE_AI_IMAGE_FAST": "ai_image_fast",
        "FEATURE_API": "api",
        "FEATURE_ASSET": "asset",
        "FEATURE_AUTOMATION": "automation",
        "FEATURE_AVATAR_VIDEO": "avatar_video",
        "FEATURE_AVATAR_VIDEO_LONG_TEXT": "avatar_video_long_text",
        "FEATURE_BIO_LINK": "bio_link",
        "FEATURE_CAMPAIGN": "campaign",
        "FEATURE_CTA": "cta",
        "FEATURE_CUSTOM_DOMAIN": "custom_domain",
        "FEATURE_CUSTOM_FOOTER": "custom_footer",
        "FEATURE_CUSTOM_LOGO": "custom_logo",
        "FEATURE_CUSTOM_SKIN": "custom_skin",
        "FEATURE_CUSTOM_VB": "custom_vb",
        "FEATURE_DIRECT_UPLOAD_VIDEO": "direct_upload_video",
        "FEATURE_DOWNLOAD_VIDEO": "download_video",
        "FEATURE_DUPLICATE_VIDEO": "duplicate_video",
        "FEATURE_EMBED": "embed",
        "FEATURE_FORM": "form",
        "FEATURE_HD_VIDEO": "hd_video",
        "FEATURE_LANDING_PAGE": "landing_page",
        "FEATURE_NO_DUBB_AD": "no_dubb_ad",
        "FEATURE_PIXELS": "pixels",
        "FEATURE_PLAYLIST": "playlist",
        "FEATURE_SHOWCASE": "showcase",
        "FEATURE_SMS_MESSAGING": "sms_messaging",
        "FEATURE_SUB_DOMAIN": "sub_domain",
        "FEATURE_TELEPROMPTER_SCRIPT": "teleprompter_script",
        "FEATURE_TEXT_TO_VIDEO": "text_to_video",
        "FEATURE_VIDEO_ANALYSIS": "video_analysis",
        "FEATURE_VIDEO_PRESET": "video_preset",
        "FEATURE_VIDEO_TEMPLATE": "video_template",
        "QUOTA_AI_COMPLETION": "ai_completion",
        "QUOTA_AI_IMAGE": "ai_image",
        "QUOTA_AVATAR_VIDEO": "avatar_video",
        "QUOTA_CONTACTS": "contacts",
        "QUOTA_DUBB_SENDER": "dubb_sender",
        "QUOTA_STORAGE": "storage",
        "QUOTA_TRANSCRIBE": "transcribe",
        "QUOTA_VIDEO_SIZE": "video_size",
        "QUOTA_VIDEOS": "videos",
        "DEFAULT_FEATURES": {
            "4k_video": false,
            "activity_reporting_detail": false,
            "activity_reporting_general": false,
            "aggregated_reporting_general": false,
            "aggregated_reporting_video_level": false,
            "ai_agent": false,
            "ai_image_fast": false,
            "api": false,
            "asset": false,
            "automation": false,
            "avatar_video": false,
            "avatar_video_long_text": false,
            "bio_link": false,
            "campaign": false,
            "cta": false,
            "custom_domain": false,
            "custom_footer": false,
            "custom_logo": false,
            "custom_skin": false,
            "custom_vb": false,
            "direct_upload_video": false,
            "download_video": false,
            "duplicate_video": false,
            "embed": false,
            "form": false,
            "hd_video": false,
            "landing_page": false,
            "no_dubb_ad": false,
            "pixels": false,
            "playlist": false,
            "showcase": false,
            "sms_messaging": false,
            "sub_domain": false,
            "teleprompter_script": false,
            "text_to_video": false,
            "video_analysis": false,
            "video_preset": false,
            "video_template": false
        },
        "DEFAULT_QUOTAS": {
            "ai_completion": 0,
            "ai_image": 0,
            "avatar_video": 0,
            "contacts": 0,
            "dubb_sender": 0,
            "storage": 10,
            "transcribe": 0,
            "video_size": 0.15,
            "videos": 100
        },
        "CONTACT_UPLOAD_THRESHOLD": 1000,
        "CHROME_EXTENSION_URL": "https:\/\/chrome.google.com\/webstore\/detail\/comhknhgkhbecfolehchaemllofaeppb",
        "CHROME_EXTENSION_ID": "comhknhgkhbecfolehchaemllofaeppb",
        "DESKTOP_APP_URL": "https:\/\/dubb.com\/features\/desktop-app",
        "OUTLOOK_ADDIN_URL": "https:\/\/appsource.microsoft.com\/en-us\/product\/office\/WA104381649?src=health&tab=Overview",
        "PASSPORT_CLIENT_WEB": 2,
        "PASSPORT_CLIENT_CHROME_EXTENSION": 4,
        "PASSPORT_CLIENT_MOBILE_APP": 5,
        "PASSPORT_CLIENT_OUTLOOK_ADDIN": 6,
        "PASSPORT_CLIENT_DESKTOP_APP_LEGACY": 8,
        "PASSPORT_CLIENT_DESKTOP_APP_PKCE": 11,
        "PASSPORT_CLIENT_ZOOM_APP": 12,
        "BUILTIN_API_TOKEN_CHROME_EXTENSION": "_dubb_chrome_extension",
        "BUILTIN_API_TOKEN_MOBILE_APP": "_dubb_mobile_app",
        "BUILTIN_API_TOKEN_OUTLOOK_ADDIN": "_dubb_outlook_addin",
        "BUILTIN_API_TOKEN_WEB": "_dubb_web",
        "BUILTIN_API_TOKENS": [
            "_dubb_chrome_extension",
            "_dubb_mobile_app",
            "_dubb_outlook_addin",
            "_dubb_web"
        ],
        "VIDEO_RESOLUTION_LABEL_MAP": {
            "hls_1080": "1080P",
            "hls_720": "720P",
            "hls_360": "360P",
            "2160p": "4K",
            "1080p": "1080P",
            "720p": "720P",
            "360p": "360P"
        },
        "VIDEO_RESOLUTION_RES_MAP": {
            "hls_1080": "1080",
            "hls_720": "720",
            "hls_360": "360",
            "2160p": "2160",
            "1080p": "1080",
            "720p": "720",
            "360p": "360"
        },
        "TEAM_SPECIAL_SET_ANGLE_OAK": "angle_oak",
        "TEAM_SPECIAL_SET_BETHEL": "bethel",
        "TEAM_SPECIAL_SET_KCP": "kcp",
        "TEAM_SPECIAL_SET_OTOVO": "otovo",
        "TEAM_SPECIAL_SET_MANAGE_VIDEO_PRIVACY": "manage_video_privacy",
        "TEAM_SPECIAL_SET_NO_ABUSE_CHECK": "no_abuse_check",
        "TEAM_SPECIAL_SET_PROTECTED": "protected",
        "TEAM_SPECIAL_SET_SUBTITLE_COMPLIANCE_VISIBLE": "subtitle_compliance_visible",
        "TEAM_SPECIAL_SET_SUBTITLE_COMPLIANCE_INVISIBLE": "subtitle_compliance_invisible",
        "TEAM_SPECIAL_SET_MAP": {
            "angle_oak": "Angle Oak",
            "bethel": "Bethel",
            "kcp": "KCP",
            "otovo": "Otovo",
            "manage_video_privacy": "Manage Video Privacy",
            "no_abuse_check": "No Abuse Check",
            "protected": "Protected",
            "subtitle_compliance_visible": "Subtitle Visible Compliance",
            "subtitle_compliance_invisible": "Subtitle Invisible Compliance"
        },
        "VIDEO_EXTENSION_ALLOW_LIST": [
            ".mp4",
            ".webm",
            ".mkv",
            ".mov",
            ".wmv",
            ".m4v",
            ".avi",
            ".3gp",
            ".mpg",
            ".mpeg"
        ],
        "CURRENCY_SYMBOLS": {
            "AUD": "$",
            "BRL": "R$",
            "CAD": "$",
            "CZK": "K\u010d",
            "DKK": "kr",
            "EUR": "\u20ac",
            "HKD": "HK$",
            "HUF": "ft",
            "INR": "\u20b9",
            "ILS": "\u20aa",
            "JPY": "\u00a5",
            "MYR": "RM",
            "MXN": "$",
            "TWD": "TW$",
            "NZD": "$",
            "NOK": "kr",
            "PHP": "\u20b1",
            "PLN": "z\u0142",
            "GBP": "\u00a3",
            "RUB": "\u20bd",
            "SGD": "$",
            "SEK": "kr",
            "CHF": "CHF",
            "THB": "\u0e3f",
            "USD": "$"
        },
        "COUNTRY_CODES": {
            "United States \/ Canada": "+1",
            "Afghanistan": "+93",
            "Albania": "+355",
            "Algeria": "+213",
            "American Samoa": "+1-684",
            "Andorra": "+376",
            "Angola": "+244",
            "Anguilla": "+1-264",
            "Antarctica": "+672",
            "Antigua and Barbuda": "+1-268",
            "Argentina": "+54",
            "Armenia": "+374",
            "Aruba": "+297",
            "Australia": "+61",
            "Austria": "+43",
            "Azerbaijan": "+994",
            "Bahamas": "+1-242",
            "Bahrain": "+973",
            "Bangladesh": "+880",
            "Barbados": "+1-246",
            "Belarus": "+375",
            "Belgium": "+32",
            "Belize": "+501",
            "Benin": "+229",
            "Bermuda": "+1-441",
            "Bhutan": "+975",
            "Bolivia": "+591",
            "Bosnia and Herzegovina": "+387",
            "Botswana": "+267",
            "Brazil": "+55",
            "British Indian Ocean Territory": "+246",
            "British Virgin Islands": "+1-284",
            "Brunei": "+673",
            "Bulgaria": "+359",
            "Burkina Faso": "+226",
            "Burundi": "+257",
            "Cambodia": "+855",
            "Cameroon": "+237",
            "Cape Verde": "+238",
            "Cayman Islands": "+1-345",
            "Central African Republic": "+236",
            "Chad": "+235",
            "Chile": "+56",
            "China": "+86",
            "Christmas Island": "+61",
            "Cocos Islands": "+61",
            "Colombia": "+57",
            "Comoros": "+269",
            "Cook Islands": "+682",
            "Costa Rica": "+506",
            "Croatia": "+385",
            "Cuba": "+53",
            "Curacao": "+599",
            "Cyprus": "+357",
            "Czech Republic": "+420",
            "Democratic Republic of the Congo": "+243",
            "Denmark": "+45",
            "Djibouti": "+253",
            "Dominica": "+1-767",
            "Dominican Republic,": "+1-809",
            "East Timor": "+670",
            "Ecuador": "+593",
            "Egypt": "+20",
            "El Salvador": "+503",
            "Equatorial Guinea": "+240",
            "Eritrea": "+291",
            "Estonia": "+372",
            "Ethiopia": "+251",
            "Falkland Islands": "+500",
            "Faroe Islands": "+298",
            "Fiji": "+679",
            "Finland": "+358",
            "France": "+33",
            "French Polynesia": "+689",
            "Gabon": "+241",
            "Gambia": "+220",
            "Georgia": "+995",
            "Germany": "+49",
            "Ghana": "+233",
            "Gibraltar": "+350",
            "Greece": "+30",
            "Greenland": "+299",
            "Grenada": "+1-473",
            "Guadeloupe": "+590",
            "Guam": "+1-671",
            "Guatemala": "+502",
            "Guernsey": "+44-1481",
            "Guinea": "+224",
            "Guinea-Bissau": "+245",
            "Guyana": "+592",
            "Haiti": "+509",
            "Honduras": "+504",
            "Hong Kong": "+852",
            "Hungary": "+36",
            "Iceland": "+354",
            "India": "+91",
            "Indonesia": "+62",
            "Iran": "+98",
            "Iraq": "+964",
            "Ireland": "+353",
            "Isle of Man": "+44-1624",
            "Israel": "+972",
            "Italy": "+39",
            "Ivory Coast": "+225",
            "Jamaica": "+1-876",
            "Japan": "+81",
            "Jersey": "+44-1534",
            "Jordan": "+962",
            "Kazakhstan": "+7",
            "Kenya": "+254",
            "Kiribati": "+686",
            "Kosovo": "+383",
            "Kuwait": "+965",
            "Kyrgyzstan": "+996",
            "Laos": "+856",
            "Latvia": "+371",
            "Lebanon": "+961",
            "Lesotho": "+266",
            "Liberia": "+231",
            "Libya": "+218",
            "Liechtenstein": "+423",
            "Lithuania": "+370",
            "Luxembourg": "+352",
            "Macau": "+853",
            "Macedonia": "+389",
            "Madagascar": "+261",
            "Malawi": "+265",
            "Malaysia": "+60",
            "Maldives": "+960",
            "Mali": "+223",
            "Malta": "+356",
            "Marshall Islands": "+692",
            "Mauritania": "+222",
            "Mauritius": "+230",
            "Mayotte": "+262",
            "Mexico": "+52",
            "Micronesia": "+691",
            "Moldova": "+373",
            "Monaco": "+377",
            "Mongolia": "+976",
            "Montenegro": "+382",
            "Montserrat": "+1-664",
            "Morocco": "+212",
            "Mozambique": "+258",
            "Myanmar": "+95",
            "Namibia": "+264",
            "Nauru": "+674",
            "Nepal": "+977",
            "Netherlands": "+31",
            "Netherlands Antilles": "+599",
            "New Caledonia": "+687",
            "New Zealand": "+64",
            "Nicaragua": "+505",
            "Niger": "+227",
            "Nigeria": "+234",
            "Niue": "+683",
            "North Korea": "+850",
            "Northern Mariana Islands": "+1-670",
            "Norway": "+47",
            "Oman": "+968",
            "Pakistan": "+92",
            "Palau": "+680",
            "Palestine": "+970",
            "Panama": "+507",
            "Papua New Guinea": "+675",
            "Paraguay": "+595",
            "Peru": "+51",
            "Philippines": "+63",
            "Pitcairn": "+64",
            "Poland": "+48",
            "Portugal": "+351",
            "Puerto Rico 1-787,": "+1-939",
            "Qatar": "+974",
            "Republic of the Congo": "+242",
            "Reunion": "+262",
            "Romania": "+40",
            "Russia": "+7",
            "Rwanda": "+250",
            "Saint Barthelemy": "+590",
            "Saint Helena": "+290",
            "Saint Kitts and Nevis": "+1-869",
            "Saint Lucia": "+1-758",
            "Saint Martin": "+590",
            "Saint Pierre and Miquelon": "+508",
            "Saint Vincent and the Grenadines": "+1-784",
            "Samoa": "+685",
            "San Marino": "+378",
            "Sao Tome and Principe": "+239",
            "Saudi Arabia": "+966",
            "Senegal": "+221",
            "Serbia": "+381",
            "Seychelles": "+248",
            "Sierra Leone": "+232",
            "Singapore": "+65",
            "Sint Maarten": "+1-721",
            "Slovakia": "+421",
            "Slovenia": "+386",
            "Solomon Islands": "+677",
            "Somalia": "+252",
            "South Africa": "+27",
            "South Korea": "+82",
            "South Sudan": "+211",
            "Spain": "+34",
            "Sri Lanka": "+94",
            "Sudan": "+249",
            "Suriname": "+597",
            "Svalbard and Jan Mayen": "+47",
            "Swaziland": "+268",
            "Sweden": "+46",
            "Switzerland": "+41",
            "Syria": "+963",
            "Taiwan": "+886",
            "Tajikistan": "+992",
            "Tanzania": "+255",
            "Thailand": "+66",
            "Togo": "+228",
            "Tokelau": "+690",
            "Tonga": "+676",
            "Trinidad and Tobago": "+1-868",
            "Tunisia": "+216",
            "Turkey": "+90",
            "Turkmenistan": "+993",
            "Turks and Caicos Islands": "+1-649",
            "Tuvalu": "+688",
            "U.S. Virgin Islands": "+1-340",
            "Uganda": "+256",
            "Ukraine": "+380",
            "United Arab Emirates": "+971",
            "United Kingdom": "+44",
            "Uruguay": "+598",
            "Uzbekistan": "+998",
            "Vanuatu": "+678",
            "Vatican": "+379",
            "Venezuela": "+58",
            "Vietnam": "+84",
            "Wallis and Futuna": "+681",
            "Western Sahara": "+212",
            "Yemen": "+967",
            "Zambia": "+260",
            "Zimbabwe": "+263"
        },
        "ISO_3166": {
            "AF": "Afghanistan",
            "AL": "Albania",
            "DZ": "Algeria",
            "AS": "American Samoa",
            "AD": "Andorra",
            "AO": "Angola",
            "AI": "Anguilla",
            "AQ": "Antarctica",
            "AG": "Antigua and Barbuda",
            "AR": "Argentina",
            "AM": "Armenia",
            "AW": "Aruba",
            "AU": "Australia",
            "AT": "Austria",
            "AZ": "Azerbaijan",
            "BS": "Bahamas",
            "BH": "Bahrain",
            "BD": "Bangladesh",
            "BB": "Barbados",
            "BY": "Belarus",
            "BE": "Belgium",
            "BZ": "Belize",
            "BJ": "Benin",
            "BM": "Bermuda",
            "BT": "Bhutan",
            "BO": "Bolivia",
            "BQ": "Bonaire, Sint Eustatius and Saba",
            "BA": "Bosnia and Herzegovina",
            "BW": "Botswana",
            "BV": "Bouvet Island",
            "BR": "Brazil",
            "IO": "British Indian Ocean Territory",
            "BN": "Brunei Darussalam",
            "BG": "Bulgaria",
            "BF": "Burkina Faso",
            "BI": "Burundi",
            "CV": "Cabo Verde",
            "KH": "Cambodia",
            "CM": "Cameroon",
            "CA": "Canada",
            "KY": "Cayman Islands",
            "CF": "Central African Republic",
            "TD": "Chad",
            "CL": "Chile",
            "CN": "China",
            "CX": "Christmas Island",
            "CC": "Cocos Islands",
            "CO": "Colombia",
            "KM": "Comoros",
            "CD": "Democratic Republic of the Congo",
            "CG": "Republic of the Congo",
            "CK": "Cook Islands",
            "CR": "Costa Rica",
            "HR": "Croatia",
            "CU": "Cuba",
            "CW": "Cura\u00e7ao",
            "CY": "Cyprus",
            "CZ": "Czechia",
            "CI": "C\u00f4te d'Ivoire",
            "DK": "Denmark",
            "DJ": "Djibouti",
            "DM": "Dominica",
            "DO": "Dominican Republic",
            "EC": "Ecuador",
            "EG": "Egypt",
            "SV": "El Salvador",
            "GQ": "Equatorial Guinea",
            "ER": "Eritrea",
            "EE": "Estonia",
            "SZ": "Eswatini",
            "ET": "Ethiopia",
            "FK": "Falkland Islands",
            "FO": "Faroe Islands",
            "FJ": "Fiji",
            "FI": "Finland",
            "FR": "France",
            "GF": "French Guiana",
            "PF": "French Polynesia",
            "TF": "French Southern Territories",
            "GA": "Gabon",
            "GM": "Gambia",
            "GE": "Georgia",
            "DE": "Germany",
            "GH": "Ghana",
            "GI": "Gibraltar",
            "GR": "Greece",
            "GL": "Greenland",
            "GD": "Grenada",
            "GP": "Guadeloupe",
            "GU": "Guam",
            "GT": "Guatemala",
            "GG": "Guernsey",
            "GN": "Guinea",
            "GW": "Guinea-Bissau",
            "GY": "Guyana",
            "HT": "Haiti",
            "HM": "Heard Island and McDonald Islands",
            "VA": "Holy See",
            "HN": "Honduras",
            "HK": "Hong Kong",
            "HU": "Hungary",
            "IS": "Iceland",
            "IN": "India",
            "ID": "Indonesia",
            "IR": "Iran",
            "IQ": "Iraq",
            "IE": "Ireland",
            "IM": "Isle of Man",
            "IL": "Israel",
            "IT": "Italy",
            "JM": "Jamaica",
            "JP": "Japan",
            "JE": "Jersey",
            "JO": "Jordan",
            "KZ": "Kazakhstan",
            "KE": "Kenya",
            "KI": "Kiribati",
            "KP": "North Korea",
            "KR": "South Korea",
            "KW": "Kuwait",
            "KG": "Kyrgyzstan",
            "LA": "Lao People's Democratic Republic",
            "LV": "Latvia",
            "LB": "Lebanon",
            "LS": "Lesotho",
            "LR": "Liberia",
            "LY": "Libya",
            "LI": "Liechtenstein",
            "LT": "Lithuania",
            "LU": "Luxembourg",
            "MO": "Macao",
            "MG": "Madagascar",
            "MW": "Malawi",
            "MY": "Malaysia",
            "MV": "Maldives",
            "ML": "Mali",
            "MT": "Malta",
            "MH": "Marshall Islands",
            "MQ": "Martinique",
            "MR": "Mauritania",
            "MU": "Mauritius",
            "YT": "Mayotte",
            "MX": "Mexico",
            "FM": "Micronesia",
            "MD": "Moldova",
            "MC": "Monaco",
            "MN": "Mongolia",
            "ME": "Montenegro",
            "MS": "Montserrat",
            "MA": "Morocco",
            "MZ": "Mozambique",
            "MM": "Myanmar",
            "NA": "Namibia",
            "NR": "Nauru",
            "NP": "Nepal",
            "NL": "Netherlands",
            "NC": "New Caledonia",
            "NZ": "New Zealand",
            "NI": "Nicaragua",
            "NE": "Niger",
            "NG": "Nigeria",
            "NU": "Niue",
            "NF": "Norfolk Island",
            "MP": "Northern Mariana Islands",
            "NO": "Norway",
            "OM": "Oman",
            "PK": "Pakistan",
            "PW": "Palau",
            "PS": "Palestine, State of",
            "PA": "Panama",
            "PG": "Papua New Guinea",
            "PY": "Paraguay",
            "PE": "Peru",
            "PH": "Philippines",
            "PN": "Pitcairn",
            "PL": "Poland",
            "PT": "Portugal",
            "PR": "Puerto Rico",
            "QA": "Qatar",
            "MK": "Republic of North Macedonia",
            "RO": "Romania",
            "RU": "Russian Federation",
            "RW": "Rwanda",
            "RE": "R\u00e9union",
            "BL": "Saint Barth\u00e9lemy",
            "SH": "Saint Helena, Ascension and Tristan da Cunha",
            "KN": "Saint Kitts and Nevis",
            "LC": "Saint Lucia",
            "MF": "Saint Martin (French part)",
            "PM": "Saint Pierre and Miquelon",
            "VC": "Saint Vincent and the Grenadines",
            "WS": "Samoa",
            "SM": "San Marino",
            "ST": "Sao Tome and Principe",
            "SA": "Saudi Arabia",
            "SN": "Senegal",
            "RS": "Serbia",
            "SC": "Seychelles",
            "SL": "Sierra Leone",
            "SG": "Singapore",
            "SX": "Sint Maarten (Dutch part)",
            "SK": "Slovakia",
            "SI": "Slovenia",
            "SB": "Solomon Islands",
            "SO": "Somalia",
            "ZA": "South Africa",
            "GS": "South Georgia and the South Sandwich Islands",
            "SS": "South Sudan",
            "ES": "Spain",
            "LK": "Sri Lanka",
            "SD": "Sudan",
            "SR": "Suriname",
            "SJ": "Svalbard and Jan Mayen",
            "SE": "Sweden",
            "CH": "Switzerland",
            "SY": "Syrian Arab Republic",
            "TW": "Taiwan",
            "TJ": "Tajikistan",
            "TZ": "Tanzania, United Republic of",
            "TH": "Thailand",
            "TL": "Timor-Leste",
            "TG": "Togo",
            "TK": "Tokelau",
            "TO": "Tonga",
            "TT": "Trinidad and Tobago",
            "TN": "Tunisia",
            "TR": "Turkey",
            "TM": "Turkmenistan",
            "TC": "Turks and Caicos Islands",
            "TV": "Tuvalu",
            "UG": "Uganda",
            "UA": "Ukraine",
            "AE": "United Arab Emirates",
            "GB": "United Kingdom",
            "US": "United States",
            "UY": "Uruguay",
            "UZ": "Uzbekistan",
            "VU": "Vanuatu",
            "VE": "Venezuela",
            "VN": "Viet Nam",
            "VG": "Virgin Islands (British)",
            "VI": "Virgin Islands (U.S.)",
            "WF": "Wallis and Futuna",
            "EH": "Western Sahara",
            "YE": "Yemen",
            "ZM": "Zambia",
            "ZW": "Zimbabwe",
            "AX": "\u00c5land Islands"
        },
        "CHEAP_SMS_CODES": [
            "+1"
        ],
        "MAX_TRIAL_EXTENSION_COUNT": 3,
        "TRIAL_EXTENSION_DAYS": 7,
        "TRANSCRIBE_LANGUAGES": {
            "ar-AE": "Gulf Arabic",
            "ar-SA": "Modern Standard Arabic",
            "de-CH": "Swiss German",
            "de-DE": "German",
            "en-AB": "Scottish English",
            "en-AU": "Australian English",
            "en-GB": "British English",
            "en-IE": "Irish English",
            "en-IN": "Indian English",
            "en-US": "US English",
            "en-WL": "Welsh English",
            "es-ES": "Spanish",
            "es-US": "US Spanish",
            "fa-IR": "Farsi Persian",
            "fr-CA": "Canadian French",
            "fr-FR": "French",
            "he-IL": "Hebrew",
            "hi-IN": "Indian Hindi",
            "id-ID": "Indonesian",
            "it-IT": "Italian",
            "ja-JP": "Japanese",
            "ko-KR": "Korean",
            "ms-MY": "Malay",
            "nl-NL": "Dutch",
            "pt-BR": "Brazilian Portuguese",
            "pt-PT": "Portuguese",
            "ru-RU": "Russian",
            "ta-IN": "Tamil",
            "te-IN": "Telugu",
            "tr-TR": "Turkish",
            "zh-CN": "Mandarin Chinese \u2013 Mainland"
        }
    },
    "App_Models_Announcement": {
        "TYPE_CONTENT": "content",
        "TYPE_REDIRECT": "redirect"
    },
    "App_Models_AvatarVideo": {
        "STATUS_STARTING": "starting",
        "STATUS_PROCESSING": "processing",
        "STATUS_SUCCEEDED": "succeeded",
        "STATUS_FAILED": "failed",
        "STATUS_CANCELLED": "cancelled",
        "PREPROCESS_CROP": "crop",
        "PREPROCESS_EXTCROP": "extcrop",
        "PREPROCESS_FULL": "full",
        "PREPROCESS_EXTFULL": "extfull",
        "PREPROCESS_RESIZE": "resize"
    },
    "App_Models_BackgroundJob": {
        "STATUS_PENDING": "pending",
        "STATUS_PROCESSING": "processing",
        "STATUS_FINISHED": "finished",
        "STATUS_FAILED": "failed",
        "TYPE_IMPORT_CONTACTS": "import_contacts",
        "TYPE_CLEAN_CONTACTS_PHONE": "clean_contacts_phone"
    },
    "App_Models_BroadcastSetting": {
        "TYPE_EMAIL": "email",
        "TYPE_SMS": "sms",
        "PROVIDER_8X8": "8x8",
        "PROVIDER_CLICK_SEND": "click_send",
        "PROVIDER_DIALPAD": "dialpad",
        "PROVIDER_EZ_TEXTING": "ez_texting",
        "PROVIDER_GMAIL_API": "gmail_api",
        "PROVIDER_GMAIL_SMTP": "gmail_smtp",
        "PROVIDER_JUST_CALL": "just_call",
        "PROVIDER_MAILGUN": "mailgun",
        "PROVIDER_MANDRILL_SMTP": "mandrill_smtp",
        "PROVIDER_OPEN_PHONE": "open_phone",
        "PROVIDER_OUTLOOK_API": "outlook_api",
        "PROVIDER_RING_CENTRAL": "ring_central",
        "PROVIDER_SAKARI": "sakari",
        "PROVIDER_SALES_MESSAGE": "sales_message",
        "PROVIDER_SEND_GRID_SMTP": "send_grid_smtp",
        "PROVIDER_SES": "ses",
        "PROVIDER_SIGNAL_WIRE": "signal_wire",
        "PROVIDER_SIMPLE_TEXTING": "simple_texting",
        "PROVIDER_SMS_MAGIC": "sms_magic",
        "PROVIDER_SMTP": "smtp",
        "PROVIDER_SNS": "sns",
        "PROVIDER_SLICK_TEXT": "slick_text",
        "PROVIDER_TWILIO": "twilio",
        "PROVIDER_VONAGE": "vonage",
        "SMS_MESSAGING_PROVIDERS": [
            "twilio"
        ]
    },
    "App_Models_CallToAction": {
        "TYPE_CALENDAR": "calendar",
        "TYPE_CHAT": "chat",
        "TYPE_DOCUMENT": "document",
        "TYPE_EMAIL": "email",
        "TYPE_FORM": "form",
        "TYPE_IFRAME": "iframe",
        "TYPE_MESSAGE": "message",
        "TYPE_PAYMENTS": "payments",
        "TYPE_PHONE": "phone",
        "TYPE_PRODUCTS": "products",
        "TYPE_URL": "url",
        "TYPE_VIDEO_REPLY": "video_reply",
        "TYPE_WEB_PAGE": "web_page",
        "TYPE_WEBINAR": "webinar",
        "SUB_TYPE_CALL": "call",
        "SUB_TYPE_SMS": "sms",
        "SUB_TYPE_ACUITY_SCHEDULER": "acuity_scheduler",
        "SUB_TYPE_BOOK_ME": "book_me",
        "SUB_TYPE_CALENDLY": "calendly",
        "SUB_TYPE_GROOVE_APP": "groove_app",
        "SUB_TYPE_HUBSPOT": "hubspot",
        "SUB_TYPE_OTHER_CALENDAR": "other",
        "SUB_TYPE_SCHEDULE_ONCE": "schedule_once",
        "SUB_TYPE_CHILI_PIPER": "chili_piper",
        "SUB_TYPE_DUBBCAL": "dubbcal",
        "SUB_TYPE_OUTLOOK": "outlook",
        "SUB_TYPE_MESSENGER": "messenger",
        "SUB_TYPE_SKYPE": "skype",
        "SUB_TYPE_WHATS_APP": "whats_app",
        "SUB_TYPE_PAYPAL": "paypal",
        "SUB_TYPE_BIG_MARKER": "big_marker",
        "SUB_TYPE_EASY_WEBINAR": "easy_webinar",
        "SUB_TYPE_ZOOM": "zoom",
        "COMPLETE_ACTION_URL": "url",
        "COMPLETE_ACTION_MESSAGE": "message",
        "EMBED_SUPPORTED_TYPES": [
            "chat",
            "email",
            "message",
            "phone",
            "url"
        ],
        "UNIQUE_TYPES": [
            "calendar",
            "form",
            "iframe",
            "payments",
            "products",
            "web_page",
            "webinar"
        ],
        "IFRAME_TYPES": [
            "calendar",
            "iframe",
            "web_page",
            "webinar"
        ]
    },
    "App_Models_Campaign": {
        "VIDEO_PLACEHOLDER": "{{video-placeholder}}",
        "RESPONSIVE_VIDEO_PLACEHOLDER": "{{responsive-video-placeholder}}",
        "VIDEO_PLACEHOLDER_NO_BUTTON": "{{video-placeholder-no-button}}",
        "RESPONSIVE_VIDEO_PLACEHOLDER_NO_BUTTON": "{{responsive-video-placeholder-no-button}}",
        "STILL_VIDEO_PLACEHOLDER": "{{still-video-placeholder}}",
        "RESPONSIVE_STILL_VIDEO_PLACEHOLDER": "{{responsive-still-video-placeholder}}",
        "STILL_VIDEO_PLACEHOLDER_NO_BUTTON": "{{still-video-placeholder-no-button}}",
        "RESPONSIVE_STILL_VIDEO_PLACEHOLDER_NO_BUTTON": "{{responsive-still-video-placeholder-no-button}}",
        "PLACEHOLDERS": [
            "{{video-placeholder}}",
            "{{video-placeholder-no-button}}",
            "{{still-video-placeholder}}",
            "{{still-video-placeholder-no-button}}"
        ],
        "TYPE_EMAIL": "email",
        "TYPE_SMS": "sms",
        "STATUS_DRAFT": "draft",
        "STATUS_FAILED": "failed",
        "STATUS_FINISHED": "finished",
        "STATUS_PENDING": "pending",
        "STATUS_SENDING": "sending",
        "STATUS_CANCELED": "canceled"
    },
    "App_Models_CampaignContact": {
        "STATUS_EXCEEDS_LIMIT": "exceeds_limit",
        "STATUS_FAILED": "failed",
        "STATUS_PENDING": "pending",
        "STATUS_SUCCESS": "success",
        "STATUS_UNSUBSCRIBED": "unsubscribed",
        "STATUS_CANCELED": "canceled"
    },
    "App_Models_Contact": {
        "SOURCE_ACTIVE_CAMPAIGN": "active_campaign",
        "SOURCE_API": "api",
        "SOURCE_AWEBER": "aweber",
        "SOURCE_CHAT": "chat",
        "SOURCE_CLOSE": "close",
        "SOURCE_CONNECTION": "connection",
        "SOURCE_CSV": "csv",
        "SOURCE_FACEBOOK_PAGE": "facebook_page",
        "SOURCE_FOLLOW_UP_BOSS": "follow_up_boss",
        "SOURCE_FORM": "form",
        "SOURCE_GOOGLE": "google",
        "SOURCE_HIGH_LEVEL": "high_level",
        "SOURCE_HUBSPOT": "hubspot",
        "SOURCE_LOFTY": "lofty",
        "SOURCE_MANUALLY": "manually",
        "SOURCE_OUTLOOK": "outlook",
        "SOURCE_REALVOLVE": "realvolve",
        "SOURCE_SALESFORCE": "salesforce",
        "SOURCE_SIERRA_INTERACTIVE": "sierra_interactive",
        "SOURCE_WISE_AGENT": "wise_agent",
        "SOURCE_WOODPECKER": "woodpecker",
        "SOURCE_ZAPIER": "zapier"
    },
    "App_Models_ContactNote": {
        "TYPE_NOTE": "note",
        "TYPE_EMAIL_LOG": "email_log",
        "TYPE_CALL_LOG": "call_log",
        "TYPE_SMS_LOG": "sms_log",
        "CALL_OUTCOME_BUSY": "busy",
        "CALL_OUTCOME_CONNECTED": "connected",
        "CALL_OUTCOME_LEFT_LIVE_MESSAGE": "left_live_message",
        "CALL_OUTCOME_LEFT_VOICEMAIL": "left_voicemail",
        "CALL_OUTCOME_NO_ANSWER": "no_answer",
        "CALL_OUTCOME_WRONG_NUMBER": "wrong_number"
    },
    "App_Models_ContactWorkflow": {
        "STATUS_FINISHED": "finished",
        "STATUS_RUNNING": "running",
        "STATUS_STOPPED": "stopped"
    },
    "App_Models_CrmCustomProperty": {
        "FORM_CONTROL_TYPE_SINGLE_LINE_TEXT": "single_line_text",
        "FORM_CONTROL_TYPE_MULTI_LINE_TEXT": "multi_line_text",
        "FORM_CONTROL_TYPE_SWITCH": "switch",
        "FORM_CONTROL_TYPE_CHECKBOXES": "checkboxes",
        "FORM_CONTROL_TYPE_CONSENT_CHECKBOX": "consent_checkbox",
        "FORM_CONTROL_TYPE_DROPDOWN": "dropdown",
        "FORM_CONTROL_TYPE_DATE": "date"
    },
    "App_Models_CrmStage": {
        "TYPE_CONTACT": "contact",
        "TYPE_DEAL": "deal"
    },
    "App_Models_DubbObject": {
        "TYPE_COACH": "coach",
        "TYPE_CRM_OPTION": "crm-option",
        "TYPE_DASHBOARD_MESSAGE": "dashboard-message",
        "TYPE_FAQ": "faq",
        "TYPE_INTEGRATION": "integration",
        "TYPE_MRSS": "mrss",
        "TYPE_OPTIMIZE_FOR": "optimize-for",
        "TYPE_OTHER_LEAD_EMAIL": "other-lead-email",
        "TYPE_SIGN_UP_LEAD_EMAIL": "sign-up-lead-email",
        "TYPE_PARTNER": "partner",
        "INTEGRATION_CATEGORY_AD_PLATFORMS": "ad-platforms",
        "INTEGRATION_CATEGORY_COLLABORATION": "collaboration",
        "INTEGRATION_CATEGORY_CRM": "crm",
        "INTEGRATION_CATEGORY_EMAIL_MARKETING": "email-marketing",
        "INTEGRATION_CATEGORY_MARKETING_AUTOMATION": "marketing-automation",
        "INTEGRATION_CATEGORY_PROJECT_MANAGEMENT": "project-management",
        "INTEGRATION_CATEGORY_SALES_ENABLEMENT": "sales-enablement"
    },
    "App_Models_EmailTemplate": {
        "EDITOR_TYPE_GRAPESJS": "grapesjs",
        "EDITOR_TYPE_UNLAYER": "unlayer"
    },
    "App_Models_Form": {
        "COMPLETE_ACTION_FORWARD_URL": "forward_url",
        "COMPLETE_ACTION_MESSAGE": "message"
    },
    "App_Models_FormField": {
        "TYPE_BUILTIN": "builtin",
        "TYPE_TEAM_CUSTOM_PROPERTY": "team_custom_property",
        "FIELD_NAME_COMPANY": "company_name",
        "FIELD_NAME_EMAIL": "email",
        "FIELD_NAME_FIRST_NAME": "first_name",
        "FIELD_NAME_LAST_NAME": "last_name",
        "FIELD_NAME_MOBILE": "mobile",
        "FIELD_NAME_PHONE": "phone",
        "FIELD_NAME_TITLE": "title"
    },
    "App_Models_Integration": {
        "TYPE_ACTIVE_CAMPAIGN": "active_campaign",
        "TYPE_AWEBER": "aweber",
        "TYPE_CLOSE": "close",
        "TYPE_FACEBOOK_PAGE": "facebook_page",
        "TYPE_FOLLOW_UP_BOSS": "follow_up_boss",
        "TYPE_GOOGLE_CONTACT": "google_contact",
        "TYPE_HIGH_LEVEL": "high_level",
        "TYPE_HUBSPOT": "hubspot",
        "TYPE_INFUSIONSOFT": "infusionsoft",
        "TYPE_LOFTY": "lofty",
        "TYPE_MS_CONTACT": "ms_contact",
        "TYPE_ONTRAPORT": "ontraport",
        "TYPE_PIPEDRIVE": "pipedrive",
        "TYPE_REALVOLVE": "realvolve",
        "TYPE_SALESFORCE": "salesforce",
        "TYPE_SALESLOFT": "salesloft",
        "TYPE_SLACK": "slack",
        "TYPE_SIERRA_INTERACTIVE": "sierra_interactive",
        "TYPE_SUGAR_CRM": "sugar_crm",
        "TYPE_WISEAGENT": "wiseagent",
        "TYPE_WOODPECKER": "woodpecker",
        "OPTIONS_CREATE_CONTACT": "create_contact",
        "OPTIONS_SALESFORCE_CREATE_AS_LEAD": "create_as_lead",
        "OPTIONS_SALESFORCE_PARENT_CAMPAIGN_ID": "parent_campaign_id",
        "OPTIONS_CLOSE_WEBHOOK_ID": "webhook_id",
        "OPTIONS_CLOSE_WEBHOOK_SIG_KEY": "webhook_sig_key",
        "OPTIONS_FOLLOW_UP_BOSS_WEBHOOK_IDS": "webhook_ids",
        "OPTIONS_LOFTY_WEBHOOK_SUBSCRIBE_ID": "webhook_subscribe_id",
        "OPTIONS_SIERRA_INTERACTIVE_WEBHOOK_ID": "webhook_id",
        "OPTIONS_WISE_AGENT_WEBHOOK_IDS": "webhook_ids",
        "OPTIONS_WOODPECKER_ENABLE_WEBHOOK": "enable_webhook",
        "OPTIONS_CONTACT_TAGS": "contact_tags",
        "OPTIONS_CONTACT_OWNER_ID": "contact_owner_id",
        "DEFAULT_WEBHOOK_INTEGRATION_OPTIONS": {
            "contact_tags": [],
            "contact_owner_id": null
        },
        "INDIVIDUAL_BASED_INTEGRATIONS": [
            "google_contact",
            "ms_contact"
        ]
    },
    "App_Models_KioskUser": {
        "PERMISSION_ADMIN": "admin",
        "PERMISSION_ANNOUNCEMENTS": "announcements",
        "PERMISSION_EMAIL_TEMPLATES": "email_templates",
        "PERMISSION_LANDING_PAGE_TEMPLATES": "landing_page_templates",
        "PERMISSION_LEADS": "leads",
        "PERMISSION_PAGES": "pages",
        "PERMISSION_SETTINGS": "settings",
        "PERMISSION_SUBSCRIBERS": "subscribers",
        "PERMISSION_USERS": "users",
        "PERMISSION_VIDEO_TEMPLATES": "video_templates"
    },
    "App_Models_OAuthProfile": {
        "TYPE_FACEBOOK": "facebook",
        "TYPE_GOOGLE": "google",
        "TYPE_INSTAGRAM": "instagram",
        "TYPE_LINKEDIN": "linkedin",
        "TYPE_TWITTER": "twitter"
    },
    "App_Models_PartnerPromoCode": {
        "PARTNER_APP_SUMO": "AppSumo"
    },
    "App_Models_SavedFilter": {
        "TYPE_CONTACT": "contact",
        "TYPE_TASK": "task"
    },
    "App_Models_Setting": {
        "SETTING_AI_AGENT_LEAD_CAPTURE_PROMPT": "ai_agent_lead_capture_prompt",
        "SETTING_AI_AGENT_SYSTEM_PROMPT": "ai_agent_system_prompt",
        "SETTING_AI_ASSISTANT_INDUSTRIES": "ai_assistant_industries",
        "SETTING_AI_ASSISTANT_LEAD_TEXTS": "ai_assistant_lead_texts",
        "SETTING_AI_ASSISTANT_EXAMPLE_PROMPTS": "ai_assistant_example_prompts",
        "SETTING_AI_ASSISTANT_TONES": "ai_assistant_tones",
        "SETTING_AVATAR_VIDEO_MODELS": "avatar_video_models",
        "SETTING_BAD_WORDS": "bad_words",
        "SETTING_DAILY_SIGNUP_THRESHOLD": "daily_signup_threshold",
        "SETTING_DEFAULT_VIDEOS": "default_videos",
        "SETTING_DISABLED_EMAIL_DOMAINS": "disabled_email_domains",
        "SETTING_INDUSTRY_VIDEOS": "industry_videos",
        "SETTING_LANDING_PAGE_FOOTER_HTML": "landing_page_footer_html",
        "SETTING_LEAD_GOALS": "lead_goals",
        "SETTING_LEAD_REFERRED_BYS": "lead_referred_bys",
        "SETTING_NAV_ABOUT_LINKS": "nav_about_links",
        "SETTING_NAV_INDUSTRIES_LINKS": "nav_industries_links",
        "SETTING_NAV_MOBILE_RESOURCES_LINKS": "nav_mobile_resources_links",
        "SETTING_NAV_PAGES_LINKS": "nav_pages_links",
        "SETTING_NAV_RESOURCES_LINKS": "nav_resources_links",
        "SETTING_PLACEHOLDER_VIDEO": "placeholder_video",
        "SETTING_SIDEBAR_RESOURCES": "sidebar_resources",
        "SETTING_SIDEBAR_VIDEOS": "sidebar_videos",
        "SETTING_SIGNUP_SOURCES": "signup_sources",
        "SETTING_SSL_PRIVATE_KEY": "ssl_private_key",
        "SETTING_SSL_PUBLIC_KEY": "ssl_public_key",
        "SETTING_SUBSCRIPTION_CANCELLATION_REASONS": "subscription_cancellation_reasons",
        "SETTING_SUPPORT_ARTICLES": "support_articles",
        "SETTING_VIRTUAL_BACKGROUND_IMAGES": "virtual_background_images",
        "SETTING_EMPATHY_WORDS": "empathy_words",
        "SETTING_FILLER_WORDS": "filler_words",
        "SETTING_IGNORED_REPETITION_WORDS": "ignored_repetition_words",
        "SETTING_JARGON_WORDS": "jargon_words",
        "SETTING_NEGATIVE_WORDS": "negative_words",
        "SETTING_PROFANITY_WORDS": "profanity_words",
        "SETTING_SALESY_WORDS": "salesy_words",
        "SETTING_SLANG_WORDS": "slang_words",
        "MARKDOWN_TERMS": "markdown:terms",
        "MARKDOWN_PRIVACY": "markdown:privacy",
        "MARKDOWN_REFUND_POLICY": "markdown:refund-policy",
        "MARKDOWN_COOKIE_POLICY": "markdown:cookie-policy"
    },
    "App_Models_Statistics": {
        "TYPE_BROWSER": "browser",
        "TYPE_CITY": "city",
        "TYPE_CRM": "crm",
        "TYPE_DEVICE": "device",
        "TYPE_INDUSTRY": "industry",
        "TYPE_UTM_SOURCE": "utm_source",
        "TYPE_UTM_MEDIUM": "utm_medium",
        "TYPE_UTM_CAMPAIGN": "utm_campaign",
        "TYPE_UTM_CONTENT": "utm_content",
        "TYPE_UTM_TERM": "utm_term",
        "TYPE_SUBSCRIBER_BROWSER": "subscriber_browser",
        "TYPE_SUBSCRIBER_CITY": "subscriber_city",
        "TYPE_SUBSCRIBER_CRM": "subscriber_crm",
        "TYPE_SUBSCRIBER_DEVICE": "subscriber_device",
        "TYPE_SUBSCRIBER_INDUSTRY": "subscriber_industry",
        "TYPE_SUBSCRIBER_UTM_SOURCE": "subscriber_utm_source",
        "TYPE_SUBSCRIBER_UTM_MEDIUM": "subscriber_utm_medium",
        "TYPE_SUBSCRIBER_UTM_CAMPAIGN": "subscriber_utm_campaign",
        "TYPE_SUBSCRIBER_UTM_CONTENT": "subscriber_utm_content",
        "TYPE_SUBSCRIBER_UTM_TERM": "subscriber_utm_term",
        "TYPE_DAILY_CANCELLATION": "daily_cancellation",
        "TYPE_DAILY_SUBSCRIBER_QUANTITY": "daily_subscriber_quantity",
        "TYPE_MONTHLY_SUBSCRIBER_QUANTITY": "monthly_subscriber_quantity",
        "TYPE_YEARLY_SUBSCRIBER_QUANTITY": "yearly_subscriber_quantity",
        "TYPE_MONTHLY_PAID_USERS": "monthly_paid_users",
        "TYPE_TOTAL_USERS": "total_users",
        "TYPE_TOTAL_LEADS": "total_leads",
        "TYPE_TOTAL_SUBSCRIBERS": "total_subscribers",
        "TYPE_TOTAL_FREE_USERS": "total_free_users",
        "TYPE_TOTAL_TEAMS": "total_teams",
        "TYPE_TRIAL_TEAMS": "trial_teams",
        "TYPE_SIGN_UP_RATE": "sign_up_rate",
        "TYPE_SUBSCRIBE_RATE": "subscribe_rate",
        "TYPE_AVG_SIGN_UP_TIME": "avg_sign_up_time",
        "TYPE_AVG_SUBSCRIBE_TIME": "avg_subscribe_time",
        "TYPE_VIRAL_COEFFICIENT": "viral_coefficient",
        "TYPE_NEW_USER": "new_user",
        "TYPE_MONTHLY_RECURRING_REVENUE": "monthly_recurring_revenue",
        "TYPE_YEARLY_RECURRING_REVENUE": "yearly_recurring_revenue",
        "TYPE_DAILY_VOLUME": "daily_volume",
        "TYPE_TOTAL_VOLUME": "total_volume",
        "TYPE_ACTIVE_USERS_DAILY": "active_users_daily",
        "TYPE_ACTIVE_USERS_WEEKLY": "active_users_weekly",
        "TYPE_ACTIVE_USERS_MONTHLY": "active_users_monthly",
        "TYPE_ACTIVE_FREE_USERS_DAILY": "active_free_users_daily",
        "TYPE_ACTIVE_FREE_USERS_WEEKLY": "active_free_users_weekly",
        "TYPE_ACTIVE_FREE_USERS_MONTHLY": "active_free_users_monthly",
        "TYPE_ACTIVE_PAID_USERS_DAILY": "active_paid_users_daily",
        "TYPE_ACTIVE_PAID_USERS_WEEKLY": "active_paid_users_weekly",
        "TYPE_ACTIVE_PAID_USERS_MONTHLY": "active_paid_users_monthly"
    },
    "App_Models_Tag": {
        "TYPE_CONTACT": "contact",
        "TYPE_SCRIPT": "script",
        "TYPE_VIDEO": "video",
        "ACTION_EDIT": "edit",
        "ACTION_DELETE": "delete"
    },
    "App_Models_Task": {
        "STATUS_PENDING": "pending",
        "STATUS_COMPLETED": "finished",
        "RECURRING_PERIOD_DAILY": "daily",
        "RECURRING_PERIOD_WEEKLY": "weekly",
        "RECURRING_PERIOD_MONTHLY": "monthly",
        "RECURRING_PERIOD_YEARLY": "yearly",
        "RECURRING_PERIOD_PERIODICALLY": "periodically",
        "BUILTIN_TYPE_UPDATE_LOGO": "update_logo",
        "BUILTIN_TYPE_CREATE_CTA": "create_cta",
        "BUILTIN_TYPE_CREATE_PRESET": "create_preset",
        "BUILTIN_TYPE_CONNECT_LINKEDIN": "connect_linkedin",
        "BUILTIN_TYPE_JOIN_AFFILIATION": "join_affiliation",
        "BUILTIN_TYPE_SETUP_CRM": "setup_crm",
        "BUILTIN_TYPE_VERIFY_EMAIL": "verify_email",
        "BUILTIN_TYPE_VERIFY_PHONE": "verify_phone"
    },
    "App_Models_TeamAsset": {
        "CATEGORY_AVATAR_VIDEO": "avatar_video",
        "CATEGORY_CTA_DOCUMENT": "cta_document",
        "CATEGORY_VIRTUAL_BACKGROUND": "virtual_background",
        "CATEGORY_VIDEO_TEMPLATE": "video_template",
        "CATEGORY_EMAIL_TEMPLATE": "email_template",
        "CATEGORY_LANDING_PAGE": "landing_page",
        "TYPE_IMAGE": "image",
        "TYPE_DOC": "doc",
        "TYPE_IMAGE_EXTENSION_ALLOW_LIST": [
            "jpeg",
            "png",
            "jpg",
            "gif"
        ],
        "TYPE_DOC_EXTENSION_ALLOW_LIST": [
            "pdf",
            "doc",
            "docx",
            "ppt",
            "pptx",
            "xls",
            "xlsx"
        ]
    },
    "App_Models_TeamDomain": {
        "TYPE_LANDING_PAGE": "landing_page",
        "TYPE_VIDEO": "video"
    },
    "App_Models_TeamConfigurationSet": {
        "VIDEO_PAGE_DISPLAY_BUSINESS_PHONE": "video_page_display_business_phone",
        "VIDEO_PAGE_DISPLAY_COMPANY_NAME": "video_page_display_company_name",
        "VIDEO_PAGE_DISPLAY_EMAIL_ADDRESS": "video_page_display_email_address",
        "VIDEO_PAGE_DISPLAY_MOBILE_PHONE": "video_page_display_mobile_phone",
        "VIDEO_PAGE_DISPLAY_NAME": "video_page_display_name",
        "VIDEO_PAGE_DISPLAY_PROFILE_IMAGE": "video_page_display_profile_image",
        "VIDEO_PAGE_DISPLAY_PROFILE_TITLE": "video_page_display_profile_title",
        "VIDEO_PAGE_DISPLAY_PROFILE_SUMMARY": "video_page_display_profile_summary",
        "VIDEO_PAGE_DISPLAY_SOCIAL_LINK": "video_page_display_social_links",
        "VIDEO_PAGE_DISPLAY_WEB_ADDRESS": "video_page_display_web_address",
        "SOCIAL_URL_FACEBOOK": "social_url_facebook",
        "SOCIAL_URL_INSTAGRAM": "social_url_instagram",
        "SOCIAL_URL_LINKEDIN": "social_url_linkedin",
        "SOCIAL_URL_TIKTOK": "social_url_tiktok",
        "SOCIAL_URL_TWITTER": "social_url_twitter",
        "SOCIAL_URL_YOUTUBE": "social_url_youtube",
        "BRANDING_DISABLE_COPY_FOR_EMAIL": "branding_disable_copy_for_email",
        "BRANDING_DISABLE_EMAIL_CAMPAIGN": "branding_disable_email_campaign",
        "BRANDING_DISABLE_VIDEO_EMBED": "branding_disable_video_embed",
        "BRANDING_DISABLE_VIDEO_WIDGET": "branding_disable_video_widget",
        "BRANDING_DISABLE_SHOWCASE_PAGE": "branding_disable_showcase_page"
    },
    "App_Models_TeamMonthlyUsage": {
        "TYPE_AI_AGENT": "ai_agent",
        "TYPE_AI_COMPLETION": "ai_completion",
        "TYPE_AI_IMAGE": "ai_image",
        "TYPE_AVATAR_VIDEO": "avatar_video",
        "TYPE_ASSET_TRAFFIC": "asset_traffic",
        "TYPE_ASSET_VIEW": "asset_view",
        "TYPE_DUBB_SENDER": "dubb_sender",
        "TYPE_TRANSCRIBE": "transcribe",
        "TYPE_WORKFLOW": "workflow"
    },
    "App_Models_TeamRole": {
        "PERMISSION_AFFILIATE": "affiliate",
        "PERMISSION_ANNOUNCEMENT": "announcement",
        "PERMISSION_ASSET_VIEW_ALL": "asset:view_all",
        "PERMISSION_BROADCAST_SETTING_VIEW_ALL": "broadcast_setting:view_all",
        "PERMISSION_CAMPAIGN": "campaign",
        "PERMISSION_CAMPAIGN_VIEW_ALL": "campaign:view_all",
        "PERMISSION_CONTACT_DELETE": "contact:delete",
        "PERMISSION_CONTACT_EDIT": "contact:edit",
        "PERMISSION_CONTACT_EXPORT": "contact:export",
        "PERMISSION_CONTACT_IMPORT": "contact:import",
        "PERMISSION_CONTACT_SYNC": "contact:sync",
        "PERMISSION_CONTACT_TAG_EDIT": "contact_tag:edit",
        "PERMISSION_CONTACT_TAG_DELETE": "contact_tag:delete",
        "PERMISSION_CONTACT_VIEW_ALL": "contact:view_all",
        "PERMISSION_CTA_DELETE": "cta:delete",
        "PERMISSION_CTA_EDIT": "cta:edit",
        "PERMISSION_CTA_VIEW_ALL": "cta:view_all",
        "PERMISSION_DEAL": "deal",
        "PERMISSION_DEAL_VIEW_ALL": "deal:view_all",
        "PERMISSION_EMAIL_TEMPLATE": "email_template",
        "PERMISSION_FORM": "form",
        "PERMISSION_GROUP": "group",
        "PERMISSION_INTEGRATION": "integration",
        "PERMISSION_MESSAGING": "messaging",
        "PERMISSION_PRESET_DELETE": "preset:delete",
        "PERMISSION_PRESET_EDIT": "preset:edit",
        "PERMISSION_PRESET_VIEW_ALL": "preset:view_all",
        "PERMISSION_SCRIPT_DELETE": "script:delete",
        "PERMISSION_SCRIPT_EDIT": "script:edit",
        "PERMISSION_SCRIPT_VIEW_ALL": "script:view_all",
        "PERMISSION_SCRIPT_TAG_EDIT": "script_tag:edit",
        "PERMISSION_SCRIPT_TAG_DELETE": "script_tag:delete",
        "PERMISSION_SHOWCASE_DELETE": "showcase:delete",
        "PERMISSION_SHOWCASE_EDIT": "showcase:edit",
        "PERMISSION_SHOWCASE_VIEW_ALL": "showcase:view_all",
        "PERMISSION_TASK": "task",
        "PERMISSION_TEAM_BILLING": "team:billing",
        "PERMISSION_TEAM_CONFIGURATION_SET": "team:configuration_set",
        "PERMISSION_TEAM_MEMBER": "team:member",
        "PERMISSION_TEAM_PROFILE": "team:profile",
        "PERMISSION_TEAM_ROLE": "team:role",
        "PERMISSION_VIDEO_DELETE": "video:delete",
        "PERMISSION_VIDEO_EDIT": "video:edit",
        "PERMISSION_VIDEO_MANAGE_PRIVACY": "video:manage_privacy",
        "PERMISSION_VIDEO_TAG_EDIT": "video_tag:edit",
        "PERMISSION_VIDEO_TAG_DELETE": "video_tag:delete",
        "PERMISSION_VIDEO_TEMPLATE": "video:template",
        "PERMISSION_VIDEO_TEMPLATE_VIEW_ALL": "video:template:view_all",
        "PERMISSION_VIDEO_VIEW_PRIVATE": "video:view-private",
        "PERMISSION_VIDEO_VIEW_GROUP_PRIVATE": "video:view-group-private",
        "PERMISSION_WORKFLOW": "workflow",
        "DEFAULT_ADMIN_PERMISSIONS": [
            "affiliate",
            "announcement",
            "asset:view_all",
            "broadcast_setting:view_all",
            "campaign",
            "campaign:view_all",
            "contact:delete",
            "contact:edit",
            "contact:export",
            "contact:import",
            "contact:sync",
            "contact_tag:edit",
            "contact_tag:delete",
            "contact:view_all",
            "cta:delete",
            "cta:edit",
            "cta:view_all",
            "deal",
            "deal:view_all",
            "email_template",
            "form",
            "group",
            "integration",
            "messaging",
            "preset:delete",
            "preset:edit",
            "preset:view_all",
            "script:delete",
            "script:edit",
            "script:view_all",
            "script_tag:delete",
            "script_tag:edit",
            "showcase:delete",
            "showcase:edit",
            "showcase:view_all",
            "task",
            "team:billing",
            "team:configuration_set",
            "team:member",
            "team:profile",
            "team:role",
            "video:delete",
            "video:edit",
            "video_tag:edit",
            "video_tag:delete",
            "video:template",
            "video:template:view_all",
            "video:view-private",
            "workflow"
        ],
        "DEFAULT_USER_PERMISSIONS": [
            "affiliate",
            "campaign",
            "contact:delete",
            "contact:edit",
            "contact:sync",
            "contact:view_all",
            "cta:delete",
            "cta:edit",
            "deal",
            "email_template",
            "form",
            "integration",
            "messaging",
            "preset:delete",
            "preset:edit",
            "script:delete",
            "script:edit",
            "script_tag:delete",
            "script_tag:edit",
            "showcase:delete",
            "showcase:edit",
            "task",
            "video:delete",
            "video:edit",
            "workflow"
        ]
    },
    "App_Models_TeamSetting": {
        "ADDRESS_INFO": "address_info",
        "COMPANY_DESCRIPTION": "company_description",
        "COMPANY_URL": "company_url",
        "CUSTOM_FOOTER": "custom_footer",
        "FOOTER_SHOW_SOCIAL": "footer_show_social",
        "LANDING_PAGE_FAVICON": "landing_page_favicon",
        "LANDING_PAGE_PIXEL_GOOGLE_ID": "landing_page_pixel_google_id",
        "LANDING_PAGE_PIXEL_FACEBOOK_ID": "landing_page_pixel_facebook_id",
        "PAYPAL_CLIENT_ID": "paypal_client_id",
        "PAYPAL_CURRENCY": "paypal_currency",
        "PIXELS_AD_ROLL_AD_ID": "pixels_ad_roll_ad_id",
        "PIXELS_AD_ROLL_PX_ID": "pixels_ad_roll_px_id",
        "PIXELS_DRIFT": "pixels_drift",
        "PIXELS_FACEBOOK": "pixels_facebook",
        "PIXELS_GOOGLE_AD": "pixels_google_ad",
        "PIXELS_GOOGLE_AW": "pixels_google_aw",
        "PIXELS_HUBSPOT": "pixels_hubspot",
        "PIXELS_INFLUENCE": "pixels_influence",
        "PIXELS_INTERCOM": "pixels_intercom",
        "PIXELS_LINKEDIN": "pixels_linkedin",
        "FACEBOOK_URL": "facebook_url",
        "INSTAGRAM_URL": "instagram_url",
        "LINKEDIN_URL": "linkedin_url",
        "TIKTOK_URL": "tiktok_url",
        "TWITTER_URL": "twitter_url",
        "YOUTUBE_URL": "youtube_url"
    },
    "App_Models_TeamSsoConfiguration": [],
    "App_Models_Track": {
        "ACTION_CLICK_CTA": "click_cta",
        "ACTION_CLICK_EMAIL": "click_email",
        "ACTION_EMOJI": "emoji",
        "ACTION_FORM": "form",
        "ACTION_OPEN_EMAIL": "open_email",
        "ACTION_VIDEO_REPLY": "video_reply",
        "ACTION_VIEW": "view",
        "ACTION_WATCH": "watch",
        "PLATFORM_ANDROID": "Android",
        "PLATFORM_APPLE": "Apple",
        "PLATFORM_DESKTOP": "Desktop"
    },
    "App_Models_UserDelegation": {
        "STATUS_ACCEPTED": "accepted",
        "STATUS_IGNORED": "ignored",
        "STATUS_PENDING": "pending"
    },
    "App_Models_UserProfile": {
        "GENDER_MALE": "male",
        "GENDER_FEMALE": "female"
    },
    "App_Models_UserSetting": {
        "ACCOMPLISHMENT_EXPERIENCE": "accomplishment_experience",
        "ACUITY_SCHEDULER_PAGE": "acuity_scheduler_page",
        "APP_NOTIFICATION": "app_notification",
        "CART_ABANDONMENT_OFFER_EMAIL": "cart_abandonment_offer_email",
        "CART_ABANDONMENT_OFFER_SMS": "cart_abandonment_offer_sms",
        "CALENDLY_USERNAME": "calendly_username",
        "CAMPAIGN_EMAIL_REPLY_TO": "campaign_email_reply_to",
        "CHAT_NOTIFICATION_EMAIL": "chat_notification_email",
        "CURSOR_TRAILING": "cursor_trailing",
        "DESKTOP_NOTIFICATION": "desktop_notification",
        "DISABLE_EMAIL_TRACKING": "disable_email_tracking",
        "DISABLE_IP_TRACKING_EXCLUSION": "disable_ip_tracking_exclusion",
        "DISABLE_GIF_ANIMATION": "disable_gif_animation",
        "DISABLE_VIDEO_MSG_ENCODING": "disable_video_msg_encoding",
        "DISPLAY_BUSINESS_PHONE": "display_business_phone",
        "DISPLAY_COMPANY_NAME": "display_company_name",
        "DISPLAY_EMAIL_ADDRESS": "display_email_address",
        "DISPLAY_MOBILE_PHONE": "display_mobile_phone",
        "DISPLAY_NAME": "display_name",
        "DISPLAY_PROFILE_IMAGE": "display_profile_image",
        "DISPLAY_PROFILE_SUMMARY": "display_profile_summary",
        "DISPLAY_PROFILE_TITLE": "display_profile_title",
        "DISPLAY_SOCIAL_LINK": "display_social_link",
        "DISPLAY_WEB_ADDRESS": "display_web_address",
        "ENABLE_GDPR": "enable_GDPR",
        "FACEBOOK_USERNAME": "facebook_username",
        "LAST_CONTACT_SYNC_AT": "last_contact_sync_at",
        "SEND_EMAIL": "send_email",
        "SEND_TRIAL_EXTENSION_EMAIL": "send_trial_extension_email",
        "SEND_TRIAL_EXTENSION_SMS": "send_trial_extension_sms",
        "SKYPE_USERNAME": "skype_username",
        "TRANSCRIBE_LANGUAGE": "transcribe_language",
        "TRIAL_EXTENSION_OFFERS": "trial_extension_offers",
        "VIDEO_PERSONALIZATION_TEXT": "video_personalization_text",
        "VIDEO_SHARE_TEXT": "video_share_text",
        "WATCH_VIDEO_BUTTON_TEXT": "watch_video_button_text",
        "WHATS_APP": "whats_app",
        "DISABLE_COPY_FOR_EMAIL_BRANDING": "disable_copy_for_email_branding",
        "DISABLE_EMAIL_CAMPAIGN_BRANDING": "disable_email_campaign_branding",
        "DISABLE_VIDEO_EMBED_BRANDING": "disable_video_embed_branding",
        "DISABLE_VIDEO_WIDGET_BRANDING": "disable_video_widget_branding",
        "DISABLE_SHOWCASE_PAGE_BRANDING": "disable_showcase_page_branding",
        "DISABLE_PARTNER_FOOTER_BRANDING": "disable_partner_footer_branding",
        "DISABLE_PARTNER_TOP_NAV_BRANDING": "disable_partner_top_nav_branding",
        "FACEBOOK_URL": "facebook_url",
        "INSTAGRAM_URL": "instagram_url",
        "LINKEDIN_URL": "linkedin_url",
        "TIKTOK_URL": "tiktok_url",
        "TWITTER_URL": "twitter_url",
        "YOUTUBE_URL": "youtube_url",
        "NOTIFICATION_CHAT": "notification_chat",
        "DEFAULT_NOTIFICATION": {
            "click_cta": true,
            "click_email": true,
            "emoji": true,
            "form": true,
            "open_email": true,
            "video_reply": true,
            "view": true,
            "watch": true,
            "notification_chat": true
        },
        "DEFAULT_VALUES": {
            "video_share_text": "Check out this video from {{video-user-name}} {{video-url}}",
            "watch_video_button_text": "Watch Video {{duration}}"
        }
    },
    "App_Models_Video": {
        "PUBLISH_STATUS_PRIVATE": "private",
        "PUBLISH_STATUS_SEARCHABLE": "searchable",
        "PUBLISH_STATUS_URL_ONLY": "url_only",
        "SOURCE_CHANNEL_ANDROID": "android",
        "SOURCE_CHANNEL_CHROME_EXTENSION": "chrome_extension",
        "SOURCE_CHANNEL_IOS": "ios",
        "SOURCE_CHANNEL_MAC_OS": "mac_os",
        "SOURCE_CHANNEL_WEBSITE": "website",
        "SOURCE_CHANNEL_WINDOWS": "windows",
        "SOURCE_TYPE_AVATAR_VIDEO": "avatar-video",
        "SOURCE_TYPE_INVIDEO": "invideo",
        "SOURCE_TYPE_RECORD": "record",
        "SOURCE_TYPE_SCREEN": "screen",
        "SOURCE_TYPE_VOICE": "voice",
        "SOURCE_TYPE_UPLOAD": "upload",
        "SOURCE_TYPE_VIMEO": "vimeo",
        "SOURCE_TYPE_VLOG_EASY": "vlog-easy",
        "SOURCE_TYPE_WAVE_VIDEO": "wave.video",
        "SOURCE_TYPE_YOUTUBE": "youtube",
        "SOURCE_TYPE_ZOOM": "zoom"
    },
    "App_Models_VideoAnalysis": {
        "STATUS_PENDING": "pending",
        "STATUS_ANALYZING": "analyzing",
        "STATUS_DONE": "done"
    },
    "App_Models_WorkflowNode": {
        "ACTION_ADD_TAG": "add_tag",
        "ACTION_CONDITION": "condition",
        "ACTION_CREATE_DEAL": "create_deal",
        "ACTION_CREATE_TASK": "create_task",
        "ACTION_DELAY": "delay",
        "ACTION_NOTIFY": "notify",
        "ACTION_REMOVE_TAG": "remove_tag",
        "ACTION_SEND_CAMPAIGN": "send_campaign",
        "ACTION_UPDATE_STAGE": "update_stage",
        "CONDITION_CHECK_TYPE_INSTANT": "instant",
        "CONDITION_CHECK_TYPE_DELAYED": "delayed",
        "DELAY_TYPE_FIXED": "fixed",
        "DELAY_TYPE_DAY_TIME": "day_time",
        "DELAY_UNIT_MINUTES": "minutes",
        "DELAY_UNIT_HOURS": "hours",
        "DELAY_UNIT_DAYS": "days",
        "DELAY_UNIT_WEEKS": "weeks",
        "DELAY_UNIT_MONTHS": "months",
        "NOTIFY_VIA_EMAIL": "email"
    },
    "App_Models_Objects_AiProfileSettings": {
        "SETTING_NAME": "name",
        "SETTING_COMPANY": "company",
        "SETTING_PROFESSION": "profession",
        "SETTING_AUDIENCE": "audience",
        "SETTING_SELLING_PROPOSITION": "selling_proposition",
        "SETTING_COMPANY_OFFERS": "company_offers",
        "SETTING_DEFINITION": "definition",
        "SETTING_CALL_TO_ACTION": "action",
        "SETTING_TONES": "tones",
        "SETTING_EMOJI": "emoji",
        "SETTING_ACTION_NOTES": "action_notes",
        "SETTING_CHARM": "charm",
        "DEFAULTS": {
            "name": "",
            "company": "",
            "profession": "",
            "audience": "",
            "selling_proposition": "",
            "company_offers": "",
            "definition": "",
            "action": "",
            "tones": [],
            "emoji": false,
            "action_notes": false,
            "charm": false
        }
    },
    "App_Models_Objects_CrmObjectType": {
        "OBJECT_TYPE_COMPANY": "company",
        "OBJECT_TYPE_CONTACT": "contact",
        "OBJECT_TYPE_DEAL": "deal"
    },
    "App_Models_Objects_FormExtra": {
        "FORWARD_TYPE_SAME_TAB": "same_tab",
        "FORWARD_TYPE_NEW_WINDOW": "new_window",
        "DEFAULTS": {
            "button_color": "#0071bc",
            "title_color": "#464E5F",
            "text_color": "#464E5F",
            "url": "",
            "message": "",
            "forward_type": "new_window"
        }
    },
    "App_Models_Objects_TaskRecurringOptions": {
        "MONTHLY_DAY_LAST": "last",
        "DEFAULTS": {
            "weekly_days": [],
            "monthly_day": null,
            "periodically_days": null
        },
        "WEEKLY_DAYS_MAP": [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
        ]
    },
    "App_Models_Objects_WorkflowData": {
        "DEFAULTS": {
            "positions": []
        },
        "TYPE_POSITION_START": "start",
        "TYPE_POSITION_END": "end"
    },
    "App_Models_Objects_WorkflowCondition": {
        "DEFAULTS": {
            "type": "",
            "operator": "",
            "value": [],
            "extra": null
        },
        "OPERATOR_ALL_OF": "all_of",
        "OPERATOR_ANY": "any",
        "OPERATOR_ANY_OF": "any_of",
        "OPERATOR_NONE_OF": "none_of",
        "TYPE_CONTACT_STAGE_CHANGED": "contact_stage_changed",
        "TYPE_CTA_CLICKED": "cta_clicked",
        "TYPE_DATE_REACHED": "date_reached",
        "TYPE_DEAL_STAGE_CHANGED": "deal_stage_changed",
        "TYPE_EMAIL_OPENED": "email_opened",
        "TYPE_TAG_ADDED": "tag_added",
        "TYPE_VIDEO_PAGE_VIEWED": "video_page_viewed",
        "TYPE_VIDEO_REPLIED": "video_replied",
        "TYPE_VIDEO_WATCHED": "video_watched"
    },
    "App_Models_Objects_WorkflowCondition_VideoExtra": {
        "OPERATOR_EQUAL": "=",
        "OPERATOR_MORE_THAN": ">",
        "OPERATOR_MORE_THAN_OR_EQUAL_TO": ">=",
        "OPERATOR_LESS_THAN": "<",
        "OPERATOR_LESS_THAN_OR_EQUAL_TO": "<=",
        "DEFAULTS": {
            "watch_operator": ">",
            "watch_percentage": 0
        }
    },
    "App_Extensions_OpenAi_Enums_Voice": {
        "ALLOY": "alloy",
        "ECHO": "echo",
        "FABLE": "fable",
        "ONYX": "onyx",
        "NOVA": "nova",
        "SHIMMER": "shimmer"
    },
    "App_User": [],
    "App_Extensions_BroadcastProviders_Email_GmailApiEmailProvider": {
        "MAX_LIMIT_PER_DAY": 100,
        "DEFAULT_LIMIT_PER_DAY": 100,
        "MAX_LIMIT_PER_MINUTE": 25,
        "DEFAULT_LIMIT_PER_MINUTE": 25,
        "FIELD_FROM_ADDRESS": "from_address"
    },
    "App_Extensions_BroadcastProviders_Email_GmailSmtpProvider": {
        "MAX_LIMIT_PER_DAY": 100,
        "DEFAULT_LIMIT_PER_DAY": 100,
        "MAX_LIMIT_PER_MINUTE": 25,
        "DEFAULT_LIMIT_PER_MINUTE": 25,
        "FIELD_PASSWORD": "password",
        "FIELD_SERVER": "server",
        "FIELD_PORT": "port",
        "FIELD_USERNAME": "username",
        "FIELD_ENCRYPTION": "encryption",
        "INVALID_CREDENTIALS_MESSAGE": "Username or password is invalid.",
        "FIELD_FROM_ADDRESS": "from_address"
    },
    "App_Extensions_BroadcastProviders_Email_MailgunProvider": {
        "MAX_LIMIT_PER_MINUTE": 100,
        "FIELD_DOMAIN": "domain",
        "FIELD_KEY": "key",
        "FIELD_REGION": "region",
        "MAX_LIMIT_PER_DAY": 25000,
        "DEFAULT_LIMIT_PER_DAY": 25000,
        "DEFAULT_LIMIT_PER_MINUTE": 25,
        "FIELD_FROM_ADDRESS": "from_address"
    },
    "App_Extensions_BroadcastProviders_Email_MandrillSmtpProvider": {
        "MAX_LIMIT_PER_MINUTE": 500,
        "FIELD_SERVER": "server",
        "FIELD_PORT": "port",
        "FIELD_USERNAME": "username",
        "FIELD_PASSWORD": "password",
        "FIELD_ENCRYPTION": "encryption",
        "INVALID_CREDENTIALS_MESSAGE": "Username or password is invalid.",
        "MAX_LIMIT_PER_DAY": 25000,
        "DEFAULT_LIMIT_PER_DAY": 25000,
        "DEFAULT_LIMIT_PER_MINUTE": 25,
        "FIELD_FROM_ADDRESS": "from_address"
    },
    "App_Extensions_BroadcastProviders_Email_OutlookApiEmailProvider": {
        "MAX_LIMIT_PER_DAY": 100,
        "DEFAULT_LIMIT_PER_DAY": 100,
        "MAX_LIMIT_PER_MINUTE": 25,
        "DEFAULT_LIMIT_PER_MINUTE": 25,
        "FIELD_FROM_ADDRESS": "from_address"
    },
    "App_Extensions_BroadcastProviders_Email_SendGridSmtpProvider": {
        "MAX_LIMIT_PER_MINUTE": 600,
        "INVALID_CREDENTIALS_MESSAGE": "From email or API key is invalid.",
        "FIELD_SERVER": "server",
        "FIELD_PORT": "port",
        "FIELD_USERNAME": "username",
        "FIELD_PASSWORD": "password",
        "FIELD_ENCRYPTION": "encryption",
        "MAX_LIMIT_PER_DAY": 25000,
        "DEFAULT_LIMIT_PER_DAY": 25000,
        "DEFAULT_LIMIT_PER_MINUTE": 25,
        "FIELD_FROM_ADDRESS": "from_address"
    },
    "App_Extensions_BroadcastProviders_Email_SesEmailProvider": {
        "MAX_LIMIT_PER_MINUTE": 600,
        "MAX_LIMIT_PER_DAY": 25000,
        "DEFAULT_LIMIT_PER_DAY": 25000,
        "DEFAULT_LIMIT_PER_MINUTE": 25,
        "FIELD_FROM_ADDRESS": "from_address"
    },
    "App_Extensions_BroadcastProviders_Email_SmtpEmailProvider": {
        "MAX_LIMIT_PER_MINUTE": 100,
        "FIELD_SERVER": "server",
        "FIELD_PORT": "port",
        "FIELD_USERNAME": "username",
        "FIELD_PASSWORD": "password",
        "FIELD_ENCRYPTION": "encryption",
        "INVALID_CREDENTIALS_MESSAGE": "Username or password is invalid.",
        "MAX_LIMIT_PER_DAY": 25000,
        "DEFAULT_LIMIT_PER_DAY": 25000,
        "DEFAULT_LIMIT_PER_MINUTE": 25,
        "FIELD_FROM_ADDRESS": "from_address"
    },
    "App_Extensions_BroadcastProviders_Sms_EightXEightProvider": {
        "MAX_LIMIT_PER_DAY": 25000,
        "DEFAULT_LIMIT_PER_DAY": 25000,
        "MAX_LIMIT_PER_MINUTE": 100,
        "DEFAULT_LIMIT_PER_MINUTE": 25,
        "FIELD_FROM_ADDRESS": "from_address"
    },
    "App_Extensions_BroadcastProviders_Sms_ClickSendProvider": {
        "API_ENDPOINT": "https:\/\/rest.clicksend.com\/v3\/",
        "MAX_LIMIT_PER_DAY": 25000,
        "DEFAULT_LIMIT_PER_DAY": 25000,
        "MAX_LIMIT_PER_MINUTE": 100,
        "DEFAULT_LIMIT_PER_MINUTE": 25,
        "FIELD_FROM_ADDRESS": "from_address"
    },
    "App_Extensions_BroadcastProviders_Sms_DialpadProvider": {
        "API_ENDPOINT": "https:\/\/dialpad.com\/api\/v2\/",
        "MAX_LIMIT_PER_DAY": 25000,
        "DEFAULT_LIMIT_PER_DAY": 25000,
        "MAX_LIMIT_PER_MINUTE": 100,
        "DEFAULT_LIMIT_PER_MINUTE": 25,
        "FIELD_FROM_ADDRESS": "from_address"
    },
    "App_Extensions_BroadcastProviders_Sms_EzTextingProvider": {
        "API_ENDPOINT": "https:\/\/a.eztexting.com\/v1\/",
        "MAX_LIMIT_PER_DAY": 25000,
        "DEFAULT_LIMIT_PER_DAY": 25000,
        "MAX_LIMIT_PER_MINUTE": 100,
        "DEFAULT_LIMIT_PER_MINUTE": 25,
        "FIELD_FROM_ADDRESS": "from_address"
    },
    "App_Extensions_BroadcastProviders_Sms_JustCallProvider": {
        "API_ENDPOINT": "https:\/\/api.justcall.io\/v1",
        "MAX_LIMIT_PER_DAY": 25000,
        "DEFAULT_LIMIT_PER_DAY": 25000,
        "MAX_LIMIT_PER_MINUTE": 100,
        "DEFAULT_LIMIT_PER_MINUTE": 25,
        "FIELD_FROM_ADDRESS": "from_address"
    },
    "App_Extensions_BroadcastProviders_Sms_OpenPhoneProvider": {
        "API_ENDPOINT": "https:\/\/api.openphone.com",
        "MAX_LIMIT_PER_DAY": 25000,
        "DEFAULT_LIMIT_PER_DAY": 25000,
        "MAX_LIMIT_PER_MINUTE": 100,
        "DEFAULT_LIMIT_PER_MINUTE": 25,
        "FIELD_FROM_ADDRESS": "from_address"
    },
    "App_Extensions_BroadcastProviders_Sms_RingCentralProvider": {
        "MAX_LIMIT_PER_DAY": 25000,
        "DEFAULT_LIMIT_PER_DAY": 25000,
        "MAX_LIMIT_PER_MINUTE": 100,
        "DEFAULT_LIMIT_PER_MINUTE": 25,
        "FIELD_FROM_ADDRESS": "from_address"
    },
    "App_Extensions_BroadcastProviders_Sms_SakariProvider": {
        "API_ENDPOINT": "https:\/\/api.sakari.io",
        "MAX_LIMIT_PER_DAY": 25000,
        "DEFAULT_LIMIT_PER_DAY": 25000,
        "MAX_LIMIT_PER_MINUTE": 100,
        "DEFAULT_LIMIT_PER_MINUTE": 25,
        "FIELD_FROM_ADDRESS": "from_address"
    },
    "App_Extensions_BroadcastProviders_Sms_SalesMessageProvider": {
        "DEFAULT_LIMIT_PER_DAY": 1000,
        "DEFAULT_LIMIT_PER_MINUTE": 10,
        "API_ENDPOINT": "https:\/\/api.salesmessage.com\/pub\/v2.1\/",
        "MAX_LIMIT_PER_DAY": 25000,
        "MAX_LIMIT_PER_MINUTE": 100,
        "FIELD_FROM_ADDRESS": "from_address"
    },
    "App_Extensions_BroadcastProviders_Sms_SignalWireProvider": {
        "MAX_LIMIT_PER_DAY": 25000,
        "DEFAULT_LIMIT_PER_DAY": 25000,
        "MAX_LIMIT_PER_MINUTE": 100,
        "DEFAULT_LIMIT_PER_MINUTE": 25,
        "FIELD_FROM_ADDRESS": "from_address"
    },
    "App_Extensions_BroadcastProviders_Sms_SimpleTextingProvider": {
        "API_ENDPOINT": "https:\/\/api-app2.simpletexting.com\/v2\/",
        "MAX_LIMIT_PER_DAY": 25000,
        "DEFAULT_LIMIT_PER_DAY": 25000,
        "MAX_LIMIT_PER_MINUTE": 100,
        "DEFAULT_LIMIT_PER_MINUTE": 25,
        "FIELD_FROM_ADDRESS": "from_address"
    },
    "App_Extensions_BroadcastProviders_Sms_SmsMagicProvider": {
        "MAX_LIMIT_PER_DAY": 25000,
        "DEFAULT_LIMIT_PER_DAY": 25000,
        "MAX_LIMIT_PER_MINUTE": 100,
        "DEFAULT_LIMIT_PER_MINUTE": 25,
        "FIELD_FROM_ADDRESS": "from_address"
    },
    "App_Extensions_BroadcastProviders_Sms_SnsMessageProvider": {
        "MAX_LIMIT_PER_DAY": 25000,
        "DEFAULT_LIMIT_PER_DAY": 25000,
        "MAX_LIMIT_PER_MINUTE": 100,
        "DEFAULT_LIMIT_PER_MINUTE": 25,
        "FIELD_FROM_ADDRESS": "from_address"
    },
    "App_Extensions_BroadcastProviders_Sms_SlickTextProvider": {
        "API_ENDPOINT": "https:\/\/api.slicktext.com\/v1\/",
        "MAX_LIMIT_PER_DAY": 25000,
        "DEFAULT_LIMIT_PER_DAY": 25000,
        "MAX_LIMIT_PER_MINUTE": 100,
        "DEFAULT_LIMIT_PER_MINUTE": 25,
        "FIELD_FROM_ADDRESS": "from_address"
    },
    "App_Extensions_BroadcastProviders_Sms_TwilioProvider": {
        "MAX_LIMIT_PER_DAY": 25000,
        "DEFAULT_LIMIT_PER_DAY": 25000,
        "MAX_LIMIT_PER_MINUTE": 100,
        "DEFAULT_LIMIT_PER_MINUTE": 25,
        "FIELD_FROM_ADDRESS": "from_address"
    },
    "App_Extensions_BroadcastProviders_Sms_VonageProvider": {
        "MAX_LIMIT_PER_DAY": 25000,
        "DEFAULT_LIMIT_PER_DAY": 25000,
        "MAX_LIMIT_PER_MINUTE": 100,
        "DEFAULT_LIMIT_PER_MINUTE": 25,
        "FIELD_FROM_ADDRESS": "from_address"
    }
};